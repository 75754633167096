<template>
    <div class="page">
      <div class="page-single">
        <div class="container">
            <div class="col col-login mx-auto">
              <div class="text-center mb-6">
              </div>
              <form @submit.prevent="doLogin" class="card">
                <div class="flex text-center justify-content-center">
                  <img src="@/assets/images/venteny.jpg" class="mt-3" width="150" alt="">
                </div>
                <div class="card-body px-6 text-left">
                  <div class="card-title">Login to your account</div>
                  <div v-if="err_show" class="alert alert-danger" role="alert">
                    <font-awesome-icon icon="exclamation-triangle" class="text-danger"/>
                    <i class="fe fe-alert-triangle mr-2" aria-hidden="true"></i> {{ err_msg }}
                  </div>
                  <div class="form-group">
                    <label class="form-label">Email address</label>
                    <input autocomplete="off" v-model="v$.form.email.$model" type="email" class="form-control" placeholder="Enter email">
                    <span class="text-red-500 text-xs" v-if="v$.form.email.required.$invalid">{{ $t('form.email') }} {{ $t('invalid.required_message') }}</span>
                    <span class="text-red-500 text-xs" v-if="v$.form.email.email.$invalid">{{ $t('form.email') }} {{ $t('invalid.email_message') }}</span>
                  </div>
                  <div class="form-group">
                    <label class="form-label">
                      Password
                      <!-- <a href="./forgot-password.html" class="float-right small">I forgot password</a> -->
                    </label>
                    <input autocomplete="one-time-code" v-model="v$.form.password.$model" type="password" class="form-control" placeholder="Password">
                    <span class="text-red-500 text-xs" v-if="v$.form.password.required.$invalid">{{ $t('form.password') }} {{ $t('invalid.required_message') }}</span>
                  </div>
                  <div class="form-group">
                    <vue-hcaptcha ref="captcha" @verify="verifyCaptcha" :sitekey="sitekey"></vue-hcaptcha>
                    <span class="text-red-500 text-xs" v-if="v$.form.token.required.$invalid">Please prove you are human!</span>
                  </div>
                  <div class="form-footer">
                    <el-button @click="doLogin" type="primary" class="btn-primary btn-block" :loading=isLoading>Sign in</el-button>
                  </div>
                </div>
              </form>
            </div>
        </div>
      </div>
    </div>
</template>
<script>
import VueHcaptcha from '@hcaptcha/vue-hcaptcha';
import useVuelidate from '@vuelidate/core';
import { required, email } from '@vuelidate/validators';
import { LOGIN } from '@/store/modules/auth';
import base64RSA from '@/library/rsa-encrypt';

export default {
  name: 'Login',
  metaInfo: {
    title: 'Login',
  },
  components: {
    VueHcaptcha,
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      form: {
        email: '',
        password: '',
        token: '',
      },
      sitekey: process.env.VUE_APP_HCAPTCHA_SITE_KEY,
      err_msg: this.$t('response_error.general_error'),
      err_show: false,
      isLoading: false,
    };
  },
  mounted() {
  },
  methods: {
    async doLogin() {
      this.v$.form.$touch();
      if (this.v$.form.$error) {
        for (const d in this.statusForm) {
          if (d) this.statusForm[d] = true;
        }
        return;
      }
      this.isLoading = true;
      this.err_show = false;
      await this.$store.dispatch(LOGIN, {
        email: this.form.email,
        password: base64RSA.encrypt(this.form.password).toString('base64'),
        token: this.form.token,
      }).then(() => {
        this.$router.push({ name: 'Dashboard' });
      }).catch((err) => {
        if (err.response?.data?.error) {
          const k = `response_error.${err.response?.data?.error.title}`;
          this.err_msg = this.$t(k);
          if (this.err_msg === k) {
            this.err_msg = err.response?.data?.error.message;
          }
        }
        this.err_show = true;
        this.$refs.captcha.reset();
      });
      this.isLoading = false;
    },
    verifyCaptcha(token) {
      this.form.token = token;
    },
  },
  validations: {
    form: {
      email: {
        required,
        email,
      },
      password: {
        required,
      },
      token: {
        required,
      },
    },
  },
};
</script>